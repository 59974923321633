const Storage = {
  update: function(field, data){
    if (!localStorage) {
      return;
    }
    return localStorage.setItem(field, JSON.stringify(data))
  },
  retrieve: function(field, defaults){
    if (!localStorage) {
      return defaults
    }

    let result = localStorage.getItem(field);
    if (result === null) {
      return defaults;
    } else {
      return JSON.parse(result);
    }
  }
}

export default Storage;

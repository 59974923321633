import React, { Component } from "react";
import Textbox from "../presentation/Textbox";
import FormatOptionGroup from "../presentation/FormatOptionGroup";
import CopyLogo from "../clippy-flip.svg";
import Storage from "../util/Storage";
import GTM from "../util/GTMWrapper";

class Output extends Component {
  constructor(props) {
    super(props);

    this.state = Storage.retrieve("output", {
      quote: false,
      newline: true,
      comma: false,
    });

    this.formatting = [
      {
        key: "quote",
        label: "Quotes",
        handler: (event) => this.toggle(event, "quote"),
      },
      {
        key: "newline",
        label: "New Lines",
        handler: (event) => this.toggle(event, "newline"),
      },
      {
        key: "comma",
        label: "Comma-Separated",
        handler: (event) => this.toggle(event, "comma"),
      },
    ];
  }

  render() {
    console.debug("DEBUG: Re-rendered Output Component.");
    return (
      <dl className="form-group output">
        <Textbox
          placeholder="Output"
          value={this.getOutput()}
          onFocus={(event) => event.target.select()}
          actions={this.getTextboxActions()}
        />
        <dt className="item-count">
          {this.props.data.length} row{this.props.data.length === 1 ? "" : "s"}
        </dt>
        <p>
          <em>
            Use commas, spaces, or new lines to separate each item in your sets. Choose your output format below:
          </em>
        </p>
        <FormatOptionGroup options={this.formatting} state={this.state} />
      </dl>
    );
  }

  getTextboxActions() {
    return [
      {
        name: "Copy",
        logo: CopyLogo,
        handler: (textbox) => {
          GTM.track("DataSet", "copy_output");

          if (textbox.value) {
            textbox.select();
            if (document.execCommand("copy")) {
              console.log(`Data copied to clipboard.`);
              this.props.notifications("success", `Data copied to clipboard!`);
            } else {
              console.log("Your browser does not support copy to clipboard.");
              this.props.notifications(
                "error",
                `Your browser does not support copy to clipboard, please copy manually`
              );
            }
          } else {
            console.log("No text to copy.  Skipping...");
            this.props.notifications(
              "warning",
              `No text to copy.  Skipping...`
            );
          }
        },
      },
    ];
  }

  toggle(event, key) {
    let checkedState = event.target.checked;

    GTM.track(
      "DataSet",
      "format_output",
      `${key}_${checkedState ? "on" : "off"}`
    );

    this.setState((prevState) => {
      prevState[key] = checkedState;
      Storage.update("output", prevState);
      return prevState;
    });
  }

  getOutput() {
    return this.props.data
      .map((i) => {
        return `${this.state.quote ? `"${i}"` : i}`;
      })
      .join(
        `${this.state.comma ? "," : ""}${this.state.newline ? "\n" : ""}${
          !this.state.comma && !this.state.newline ? " " : ""
        }`
      );
  }
}

export default Output;

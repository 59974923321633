import React from "react";
import Icon from "./Icon";

export default function Textbox(props) {
  let textboxEl;

  return (
    <dd className="textbox-wrapper">
      <textarea
        id={props.id}
        placeholder={props.placeholder}
        readOnly={!!props.value}
        onChange={props.handler}
        defaultValue={props.initialValue}
        value={props.value}
        onFocus={props.onFocus}
        className="form-control"
        ref={(textbox) => (textboxEl = textbox)}
      />
      {props.actions && props.actions.length > 0 && (
        <div className="textbox-actions">
          {props.actions.map((action) => (
            <button
              key={action.name}
              onClick={() => action.handler(textboxEl, props.id)}
            >
              <Icon name={action.name} logo={action.logo} />
            </button>
          ))}
        </div>
      )}
    </dd>
  );
}

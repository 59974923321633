import React from "react";

export default function FormatOption(props) {
  return (
    <label>
      <dd className="output-item form-checkbox">
        <input
          type="checkbox"
          onClick={props.onClick}
          defaultChecked={props.checked}
        />
        <p>{props.label}</p>
      </dd>
    </label>
  );
}

import React, { Component } from "react";
import Textbox from "../presentation/Textbox";
import Icon from "../presentation/Icon";
//import PasteLogo from '../clippy.svg';
import ArrowSwitch from "../arrow-switch.svg";
import ClearLogo from "../x.svg";
import GTM from "../util/GTMWrapper";

class Input extends Component {
  render() {
    console.debug("DEBUG: Re-rendered Input Component.");
    return (
      <dl className="form-group inputs">
        <Textbox
          id="set1"
          handler={(event) =>
            this.props.handler(1, this.parse(event.target.value))
          }
          placeholder="Data Set #1"
          actions={this.getTextboxActions()}
          initialValue={this.unparse(this.props.initialData.set1)}
        />
        <button className="btn" onClick={() => this.props.swapHandler()}>
          <Icon name="Swap" logo={ArrowSwitch} />
        </button>
        <Textbox
          id="set2"
          handler={(event) =>
            this.props.handler(2, this.parse(event.target.value))
          }
          placeholder="Data Set #2"
          actions={this.getTextboxActions()}
          initialValue={this.unparse(this.props.initialData.set2)}
        />
      </dl>
    );
  }

  parse(data) {
    return data.split(/[,\n\s]+/).filter((i) => !!i);
  }

  unparse(data) {
    return data.join("\n");
  }

  getTextboxActions() {
    return [
      /*{
      name: 'Paste',
      logo: PasteLogo,
      handler: textbox => {
        textbox.focus();
        if (document.execCommand('paste')) {
          console.log("Paste successful");
        } else {
          console.log("Your browser does not support paste via javascript.");
        }
      }
    },*/
      {
        name: "Clear",
        logo: ClearLogo,
        handler: (textbox, id) => {
          GTM.track("DataSet", "clear_input", `${id}`);

          this.props.notifications("success", `Input cleared!`);
          this.props.setDataManually(id === "set2" ? 2 : 1, []);
        },
      },
    ];
  }

  shouldComponentUpdate(nextProps) {
    //console.log("DEBUG: Input shouldComponentUpdate force input overwrite", nextProps.forceInputOverwrite)
    return false;
  }
}

export default Input;

const dataLayer = window.dataLayer || { push: pushStub }

function pushStub(){
  console.log("\u25B6 Firing dummy GTM event with params:", ...arguments);
}

function track(category, action, data, metadataId){
  return dataLayer.push({
    event: 'interaction',
    interactionCategory: category,
    interactionAction: action,
    interactionData: data,
    interactionId: metadataId
  })
}

export default {
  track
}
import React from "react";

export default function Icon({ name, logo }) {
  return (
    <div
      key={name}
      className="tooltipped tooltipped-n tooltipped-no-delay octicon"
      ref={(node) => node && node.setAttribute("aria-label", name)}
    >
      <img src={logo} alt={name} />
    </div>
  );
}

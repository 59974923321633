import React, { Component } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Input from "./container/Input";
import Output from "./container/Output";
import OperationGroup from "./container/OperationGroup";
import Cookie from "js-cookie";
import Storage from "./util/Storage";
import Operations from "./util/Operations";
import GTM from "./util/GTMWrapper";

class App extends Component {
  constructor(props) {
    super(props);

    this.operations = [
      { key: "union", label: "Union", fn: Operations.union },
      {
        key: "intersection",
        label: "Intersection",
        fn: Operations.intersection,
      },
      { key: "diff", label: "Difference", fn: Operations.difference },
    ].map((op) => {
      op.setOperation = () => this.setActiveOperation(op.key);
      return op;
    });

    let initialData;
    if (this.newVisitor()) {
      initialData = {
        set1: ["Alfa", "Echo", "Lima", "Charlie"],
        set2: ["Echo", "Charlie", "Oscar"],
      };
    } else {
      initialData = Storage.retrieve("inputs", { set1: [], set2: [] });
    }

    this.state = {
      activeOperation: "union",
      data: initialData,
      forceInputOverwrite: false,
    };
  }

  notificationSystem(level, message) {
    return NotificationManager[level](message, null, 3000, null, true);
  }

  render() {
    return (
      <div>
        <OperationGroup
          operations={this.operations}
          activeOperation={this.state.activeOperation}
        />
        <Input
          handler={this.update.bind(this)}
          swapHandler={this.swapSets.bind(this)}
          initialData={this.state.data}
          notifications={this.notificationSystem}
          setDataManually={this.setDataManually.bind(this)}
        />
        <Output data={this.compute()} notifications={this.notificationSystem} />
        <NotificationContainer />
      </div>
    );
  }

  update(index, data) {
    GTM.track("DataSet", "update", `set${index}`);

    this.setState((prevState) => {
      let state = { ...prevState };
      state.data["set" + index] = data;
      Storage.update("inputs", state.data);
      return state;
    });
  }

  swapSets() {
    GTM.track("DataSet", "swap_sets");

    const set1Data = this.state.data.set1;
    const set2Data = this.state.data.set2;
    this.setDataManually(1, set2Data);
    this.setDataManually(2, set1Data);
    this.notificationSystem("success", "Data sets swapped!");
  }

  newVisitor() {
    if (Cookie.get("EXISTING") === "1") {
      return false;
    } else {
      Cookie.set("EXISTING", "1");
      return true;
    }
  }

  setActiveOperation(opKey) {
    GTM.track("DataSet", "change_operation", opKey);

    this.setState({
      activeOperation: opKey,
    });
  }

  compute() {
    return (
      this.operations
        .find((i) => i.key === this.state.activeOperation)
        .fn(this.state.data.set1, this.state.data.set2) || []
    );
  }

  setDataManually(index, data) {
    // TODO fix this
    const el = document.getElementById(`set${index}`);
    if (!el) {
      console.error(`No element found for set${index} within the DOM`);
      return;
    }
    el.value = data.join("\n");

    return this.update(index, data);
  }

  setFullStateManually(set1, set2, operation) {
    this.setDataManually(1, set1);
    this.setDataManually(2, set2);
    this.setActiveOperation(operation);
  }
}

export default App;

import React from "react";

export default function Operation(props) {
  return (
    <button
      type="button"
      onClick={props.handler}
      className={"btn" + (props.active ? " btn-primary" : "")}
    >
      {props.label}
    </button>
  );
}

import React from "react";
import FormatOption from "./FormatOption";

export default function FormatOptionGroup(props) {
  return (
    <dl className="format-group">
      {props.options.map((option) => (
        <FormatOption
          key={option.key}
          label={option.label}
          onClick={option.handler}
          checked={props.state[option.key]}
        />
      ))}
    </dl>
  );
}


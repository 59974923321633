/* eslint no-extend-native: "off" */
Array.prototype.unique = function unique(){
  return [...new Set(this)];
}

function union(a, b){
  return a.concat(b).unique().sort();
}

// a.filter(i => b.includes(i)).unique();
function intersection(a, b){
  const sortedA = a.unique().sort();
  const sortedB = b.unique().sort();

  const res = [];
  let i = 0;
  let j = 0;

  while (i < sortedA.length) {
    while (sortedB[j] < sortedA[i] && j < sortedB.length) {
      ++j;
    }

    if(sortedB[j] === sortedA[i]){
      res.push(sortedA[i]);
    }
    ++i;
  }

  return res;
}

// a.filter(i => !b.includes(i)).unique();
function difference(a, b){
  return a.filter(i => !b.includes(i)).unique();
}


export default {
  union, 
  intersection, 
  difference,
}
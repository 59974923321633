import React, { Component } from "react";
import Operation from "../presentation/Operation";

class OperationGroup extends Component {
  render() {
    console.debug("DEBUG: Re-rendered OperationGroup Component.");
    return (
      <div className="operations">
        {this.props.operations.map((op) => (
          <Operation
            key={op.key}
            id={op.key}
            label={op.label}
            handler={op.setOperation}
            active={op.key === this.props.activeOperation}
          />
        ))}
      </div>
    );
  }

  shouldComponentUpdate(nextProps) {
    return this.props.activeOperation !== nextProps.activeOperation;
  }
}

export default OperationGroup;
